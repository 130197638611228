import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from 'components/Link';
import React from 'react';
import { PaginatedList } from 'wms/molecules/PaginatedList';
import { SessionItem } from 'wms/molecules/SessionItem';

export default function ASNSession({ title, isFetching, filters, createSession, sessions, url }) {
  const sessionList = sessions; //data?.filter((t) => t.state !== 'COMPLETED') || [];

  return (
    <div>
      {isFetching && (
        <LinearProgress
          classes={{
            colorPrimary: 'bg-green-300',
          }}
        />
      )}

      <div className="flex flex-col w-full h-full">
        {/* Header Section containing Filters */}
        <div className="flex flex-row m-5 justify-center items-center">
          <div className="flex-1">
            <p className="text-base md:text-2xl antialiased sm:subpixel-antialiased md:antialiased font-semibold md:font-semibold mx-5">
              {title}
            </p>
          </div>
          {filters}
        </div>
        {/* Session List Section */}
        <PaginatedList
          dataList={sessionList}
          renderItem={(item) => (
            <Link key={item?.id} to={`${url}/${item?.id}`}>
              <SessionItem session={item} />
            </Link>
          )}
        />
        <CreateSessionButton createSession={createSession} />
      </div>
    </div>
  );
}

function CreateSessionButton({ createSession }) {
  return (
    <div className="w-screen fixed bottom-3 inset-x-1/2 justify-center items-center">
      <Button variant="contained" color="primary" onClick={createSession}>
        Create Session
      </Button>
    </div>
  );
}
